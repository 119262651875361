// restfulizer.js
/**
 * Author: Zizaco (http://forums.laravel.io/viewtopic.php?pid=32426)
 * Tweaked by rydurham
 *
 * Restfulize any hyperlink that contains a data-method attribute by creating
 * a mini form with the specified method and adding a trigger within the link.
 * Requires jQuery!
 *
 * Ex:
 * <a href="post/1" data-method="delete">destroy</a>
 * // Will trigger the route Route::delete('post/(:id)')
 *
 *  - This method ignores elements that have a '.disabled' class
 *  - Adding the '.action_confirm' class will trigger an optional confirm dialog.
 *  - Adding the 'data-message' attribute will display a cusotm message in the confirm dialog.
 *  - Adding the Session::token to 'data-token' will add a hidden input for needed for CSRF.
 */

$(function() {
    $('[data-method]').not(".disabled").append(function() {
        var methodForm = "\n";
        methodForm += "<form id='DeleteForm' action='" + $(this).attr('href') + "' method='POST' style='display:none'>\n";
        methodForm += " <input type='hidden' name='_method' value='" + $(this).attr('data-method') + "'>\n";
        if ($(this).attr('data-token')) {
            methodForm += "<input type='hidden' name='_token' value='" + $(this).attr('data-token') + "'>\n";
        }
        methodForm += "</form>\n";
        return methodForm;
    })
        .removeAttr('href')
        .on('click', function(ev) {
            if ($(this).hasClass('action_confirm')) {
                swal({
                    title: 'Are you sure?',
                    text: $(this).data('message') || "Are you sure you want to do this?",
                    type: 'warning',
                    showCancelButton: true,
                    closeOnConfirm: true,
                    closeOnCancel: true
                }, function(isConfirm) {
                    if (isConfirm) {
                        $('#DeleteForm').submit();
                    }

                });
            } else {
                $(this).find('#DeleteForm').submit();
            }
        });
});